<template>
  <div class="user-root">
    <!--    <van-dialog id="van-dialog" />-->
    <!--    <van-toast id="van-toast" />-->
    <van-cell-group>
      <van-skeleton title avatar :row="3" :loading="!user.id">
        <div style="font-size:16px;padding: 20px;display: flex;align-items:center">
          <div>
            <van-image
                round
                width="60"
                height="60"
                :src="device.avatar ||'http://cdn.youshusoft.com/avatar/2016_09_15_19125217587.jpg'"
                :show-loading="false"
            />
          </div>
          <div style="padding-left: 20px">
            <div>{{ user.nickname }}</div>

            <div v-if="device.imei"  style="padding:5px 0;font-size: 12px;display: flex;align-items:center">
              <div>当前设备: <span style="color: #0077aa">{{device.name || "未命名设备"}}</span></div>
              <div style="padding: 0 5px">
                                <van-button @click="editEvent" size="small" type="default" icon="edit" ></van-button>
<!--                <van-icon name="edit" />-->
              </div>
            </div>
          </div>
          <div style="flex-grow:1;text-align: right"> </div>
        </div>
      </van-skeleton>

    </van-cell-group>
    <van-cell-group >
      <van-cell @click="goDeviceList" title="设备列表" is-link >
        <template #icon>
          <van-icon class-prefix="iconfont" class="iconfont gpsicon-shebeiguanli" />
        </template>
      </van-cell>

<!--      <van-cell title="设备分享" @click="goDeviceShare"  is-link >-->
<!--        <template #icon>-->
<!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-fenxiang" />-->
<!--        </template>-->
<!--      </van-cell>-->
<!--      <van-cell v-if="ofkMap['renew']" title="设备续费" @click="goFence"  is-link >-->
<!--        <template #icon>-->
<!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-xufeijigoumai" />-->
<!--        </template>-->
<!--      </van-cell>-->
      <van-cell title="电子围栏" @click="goFence"  is-link >
        <template #icon>
          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-dianziweilan" />
        </template>
      </van-cell>
<!--      <van-cell v-if="user.id == 7842261" title="测试" @click="goTest"  is-link >-->
<!--        <template #icon>-->
<!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-liuliang" />-->
<!--        </template>-->
<!--      </van-cell>-->
    </van-cell-group>
<!--    <van-cell-group>-->
<!--      <van-cell title="电子围栏" @click="goFence"  is-link >-->
<!--        <template #icon>-->
<!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-dianziweilan" />-->
<!--        </template>-->
<!--      </van-cell>-->
<!--      <van-cell title="流量查询" @click="goSimcard"  is-link >-->
<!--        <template #icon>-->
<!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-liuliang" />-->
<!--        </template>-->
<!--      </van-cell>-->
<!--    </van-cell-group>-->
    <van-cell-group>
<!--      <van-cell v-if="ofkMap['userAuth']"  title="账号认证" @click="goUserAuth"  is-link >-->
<!--        <template #icon>-->
<!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-kefujingli-" />-->
<!--        </template>-->
<!--      </van-cell>-->
      <van-cell @click="goLanguage" title="语言切换"  is-link >
        <template #icon>
          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-jurassic_setup-multilingual" />
        </template>
      </van-cell>

      <van-cell  @click="goUserSetting" title="系统设置"  :url="settingUrl" is-link>
        <template #icon>
          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-shezhi" />
        </template>
      </van-cell>
      <van-cell @click="goQa" title="常见问题"  is-link url="https://gps.suogong.net/qa">
        <template #icon>
          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-wentijieda" />
        </template>
      </van-cell>
      <van-cell  title="当前版本">
        <template #icon>
          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-banben" />
        </template>
        <template #default>
          {{version}}
        </template>
      </van-cell>

      <!--      <van-cell icon="contact" @click="goAbout" title="关于我们"  is-link >-->
      <!--        <template #icon>-->
      <!--          <van-icon  class-prefix="iconfont" class="iconfont gpsicon-guanyuwomen" />-->
      <!--        </template>-->
      <!--      </van-cell>-->
    </van-cell-group>
    <div style="padding:0 20px">
      <van-button @click="exit" round type="danger" block>退出登录</van-button>
    </div>
    <LanguagePicker :onClose="closeLanguagePicker" v-if="showLanguage"></LanguagePicker>
    <!--    <div style="text-align: center;margin: 0.5rem 0;color: #576b95;font-size: 0.5rem;position: absolute;bottom: 0px;width: 100%">-->
    <!--      {{version}}-->
    <!--    </div>-->
  </div>
</template>

<script>
import {Collapse, CollapseItem, Button,Cell, CellGroup,Form ,Field,Switch
  ,Col,Row,Picker,Popup,Divider,Skeleton,PullRefresh,Image,Icon} from 'vant';
import {getUserInfo, logout} from "../../api/user";
import {readCommonParam} from "../../util/session-storage-utils";
import LanguagePicker from "./language/index"
import {
  getCurrentDeviceImei, navigateToDeviceEdit,
  navigateToDeviceList,
  navigateToDeviceShare, navigateToFenceList, navigateToLogin, navigateToSimcard,
  navigateToUserAuth
} from "../../util/jsinterfaces-util";
import {getBaseInfo} from "../../api/device";
export default {
  name: "UserIndexEnjiaaier",
  created(){
    readCommonParam(this)
    this.loadData();
  },
  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Form.name]: Form,
    [Field.name]: Field,
    [Switch.name]: Switch,
    [Col.name]: Col,
    [Row.name]: Row,
    [Picker .name]: Picker,
    [Popup .name]: Popup,
    [Divider .name]: Divider,
    [Skeleton .name]: Skeleton,
    [PullRefresh .name]: PullRefresh,
    [Button.name]: Button,
    [Image.name]: Image,
    [Icon.name]:Icon,
    [LanguagePicker.name]:LanguagePicker
  },
  data(){
    return {
      ofkMap:{},
      user:{},
      version:this.$route.query._version,
      device:{name:""},
      showLanguage:false
    }
  },
  computed:{
    settingUrl(){
      return "/user/setting";
    }
  },
  methods:{
    loadData(){
      getUserInfo().then((res ) => {
        this.user = res.data
      })
      getCurrentDeviceImei(imei => {
        if(imei){
          getBaseInfo(imei).then(res => {
            this.device = res.data
          })
        }

      });

    },
    closeLanguagePicker(){
      this.showLanguage = false
    },
    goLanguage(){
      this.showLanguage = true
    },
    goUserSetting(){

    },
    goQa(){

    },
    goAbout(){

    },
    exit(){
      logout()
      navigateToLogin()
    },
    editEvent(){
      getCurrentDeviceImei(imei => navigateToDeviceEdit(imei))

    },
    goFence(){
      navigateToFenceList()
    },
    goSimcard(){
      navigateToSimcard();
    },
    goTest(){
      getCurrentDeviceImei((imei) => {
        window.location.href="/simcard/index2?_imei=" + imei
      })

    },
    goDeviceShare(){
      getCurrentDeviceImei(imei => {
        navigateToDeviceShare(imei)
      })

    },
    goDeviceList(){
      navigateToDeviceList()
    },
    goUserAuth(){
      navigateToUserAuth();
    }
  }
}
</script>

<style scoped>
.user-root{

}
.user-root .van-cell-group{
  margin-bottom: 20px;
}
</style>
